.navbar-static-top {
    margin: 0px;
    height: 48px;
    display: flex;
    align-items: center;
    background-color: #f3f3f3;
}

.navbar-brand {
    font-size: 1.5rem;
    width: 100%;
    color: #333;
    font-size: 18px;
    font-weight: 400;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.navbar-column-left {
    height: 100%;
    flex-direction: row;
    text-align: start;
}

.navbar-column-center {
    width: 100%;
    flex: 2;
}

.navbar-column-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    margin-right: 16px;
}

.navbar-column-right div {
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.icon-user {
    color: #333;
    width: 9.3px;
    height: 13px;
    margin-right: 4px;
}

.icon-signout {
    color: #333;
    width: 12.08px;
    height: 13px;
    margin-right: 4px;
}

.user-tab {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 8px;
}

.username {
    color: #333;
    display: inline-block;
    padding: 0 0px;
    margin: 0px
}

.action-tab {
    color: #333;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: fit-content;
    align-items: center;
    margin-left: 8px;
    margin-right: 8px;
    text-decoration: none;
    transition: text-decoration 1s;
}

.action-tab:hover {
    text-decoration: underline;
    opacity: 0.7;
    transition: opacity 0.4s;


}


.label {
    margin-left: 5px;
}

@media (min-width: 768px) {
    .navbar-column-left {
        width: auto;
        height: 100%;
        flex-direction: row;
        text-align: start;
    }
}

.nav-logo {
    height: 30px;
    width: 100%;
    padding-left: 16px;
    padding-top: 8px;
}
