.file-manager-container {
  min-width: 768px;
  max-width: 1024px;
  height: 80vh;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: inherit;
}

@media screen and (max-width: 1024px) {
  .file-manager-container{
    min-width: 75vw;
  }
}

.file-manager-title {
  margin-bottom: 20px;
}

button {
  margin: 20px 12px;
  padding-right: 12px;
}

.table-container {
  margin-top: 12px;
  margin-bottom: 12px;
}

.file-uploads-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  height: auto;
}

.uploads-table-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  max-height: 1024px;
}

.browse-button {
  display: flex;
  min-height: 128px;
  padding: 10px 20px;
  border: 2px dashed #aaa;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 12px;

}

.browse-button:hover {
  border-color: #666;
}