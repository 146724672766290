.dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.node-b-board {
    position: relative;
}

.NodeBDevice {
    background-color: #fff;
    border: 2px solid #ececec;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 10px;
}


.node-b-name {
    font-size: 20px;
    text-align: center;
    padding: 0 0;
    width: auto;
}

.parameter-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    margin-top: 4px;
    padding-top: 4px;
    padding-left: 8px;
    padding-right: 8px;
    border-top: 1px solid #ccc;
}

.parameter-row.no-border {
    border-bottom: none;
}

.parameter-label {
    font-size: 18px;
    color: #333;
}

.parameter-type {
    display: block;
    font-size: 14px;
    color: #333;
}

.parameter-value {
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    color: #333;
}

.status-indicator {
    padding: 5px 10px;
    border-radius: 12px;
    color: white;
    font-weight: bold;
}

.status-indicator.on {
    background-color: #4CAF50;
}

.status-indicator.off {
    background-color: #f44336;
}

.sensor-indicator {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
}

.sensor-indicator.green {
    background-color: #4CAF50;
}

.sensor-indicator.red {
    background-color: #f44336;
}

.custom-switch {
    cursor: default !important;
}