.App {
  text-align: center;
  font-family: 'Karla', sans-serif;
}

body {
  font-family: 'Lato', sans-serif;
  background-color: #f3f3f3;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f2f3f8;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.widget-title {
  font-size: 14px;
  font-weight: 700;
  display: flex;
  height: 35px;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: center;
  padding-top: 4px;
  padding-bottom: 4px;
  letter-spacing: 0.01em;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  background: -webkit-linear-gradient(left, #333, #282c34);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.widget-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: calc(100% - 16px);
  margin-left: 0;
  margin-right: 0;
  overflow: auto;
  background-color: #fff;
  padding-top: 4px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  border: 2px solid #ececec;
  border-radius: 16px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  scroll-behavior: smooth;
  animation: fadeIn 0.8s ease-out;
  transition: border 0.3s ease-out, box-shadow 0.3s ease-out;
}

.widget-container:hover {
  border: 2px solid rgba(21, 52, 3, 0.15);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12);
}

.text-light {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: #333;
}

.text-bold {
  font-family: 'Lato', sans-serif;
  font-weight: 520;
  color: #333;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .widget-container {
    padding: 4px;
    border-radius: 8px;
  }
}