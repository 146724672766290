/* AlertsTable.css */

.dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.table-title {
    display: flex;
    font-size: 20px;
    text-align: center;
    width: 100%;
    justify-content: center;
}

table {
    justify-content: space-between;
}

thead {
    background-color: #fff !important;
}

td {
    background-color: #fff;
}

.table-scroll {
    overflow-y: auto;
    max-height: 400px;
}

.alerts-table {
    width: 100%;
    max-height: 100%;
    border-collapse: collapse;
    overflow-y: scroll;
}

.table-header {
    background-color: #fff !important;
}

.alerts-table th {
    text-align: left;
    padding: 12px;
    background-color: #fff !important;
    border-bottom: #ececec solid;
    border-width: 1px;
}

.alerts-table td {
    text-align: left;
    padding: 8px;
    color: #333;
    background-color: #fff;
    border-bottom: #ececec solid;
    border-width: 1px
}

.alerts-table tbody tr:nth-child(odd) {
    background-color: #fbfbfb;
}

.alerts-table tbody tr:hover {
    background-color: #f1f1f1;
}

.acknowledged-yes {
    padding: 5px 10px;
    border-radius: 12px;
    background-color: #4CAF50;
    color: white;
    font-weight: bold;
    text-align: center;
}

.acknowledged-no {
    padding: 5px 10px;
    border-radius: 12px;
    background-color: #f44336;
    color: white;
    font-weight: bold;
    text-align: center;
}