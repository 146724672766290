.dashboard-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    width: auto;
}

.action-tab {
    text-decoration: none;
    position: fixed;
    bottom: 54.5px;
    right: 16px;
    z-index: 100;
}

.dashboard-title {
    font-size: 24px;
}

.grid-container {
    flex-grow: 1;
    width: 100%;
    overflow: auto;
    scroll-behavior: smooth;
}

button {
    width: 100px;
    height: 28px;
}

.button-text {
    color: #333;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: fit-content;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    transition: text-decoration 1s;
    margin-left: 8px;
    margin-right: 8px;
    background-color: transparent;
}

.button-text:hover {
    text-decoration: underline;
    transition: text-decoration 1s;
}

@media (min-width: 768px) {
    .side-bar {
        width: 25%;
        /* Hide the sidebar */
    }
}

@media (min-width: 992px) {
    .side-bar {
        width: 16.777%;
        /* Hide the sidebar */
    }
}