.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.logo {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
    max-width: 420px;
}
