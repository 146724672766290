.grid-container {
    justify-content: center;
}

.reporting-container {
    height: calc(100vh - 200px);
    margin-right: 10px;
    margin-left: 10px;
}

.save-range {
    height: 63%;
}
