.chart-container {
    height: 100%;
}

.chart-device-name {
    font-size: 20px;
    color: #333;
    position: absolute;
    top: 4px;

    background-color: #fff;
    padding: 0 10px;
    width: auto;
}

/* Additional styles for loading text, if desired */
.loading {
    color: #333;
    margin-top: 20px;
}

/* Style for the chart div itself, if needed */
#chartdiv {
    width: 100%;
    height: 500px;
    /* Adjust based on your preference */
}

.chart-not-exist {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
