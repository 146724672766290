.footer-container {
    background-color: #112b26;
    display: flex;
    flex: row;
    justify-content: center;
    height: 30.5px;
}

.footer-bold-text {
    color: #fff;
    font-weight: bold;
    font-size: 13px;
    padding: 5px;
    border-top: 1px;
}

.footer-text {
    color: #fff;
    font-weight: normal;
    font-size: 13px;
    padding: 5px 0px;
    border-top: 1px;
}
