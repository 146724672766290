.field-sets-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    margin-right: 10px;
}

.stepContent {
    min-width: 600px; /* Set minimum width */
    min-height: 400px; /* Set minimum height */
    padding: 60px 40px 60px 40px;
}
