.dimmer-container {
    border: 2px solid #ececec;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 16px;
    background-color: #ffffff;
}

.dimmer-name {
    font-size: 20px;
    color: #333;
    margin: 0px;
    width: auto;
    text-align: center;
}

.dimmer-levels {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dimmer-dot {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 2px;
    margin-right: 2px;
    transition: background-color 0.3s;
}

.dimmer-lit {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1), 0 0 10px rgba(100, 231, 253, 1), inset 0 0 8px rgba(61, 157, 247, 0.8), inset 0 -2px 5px rgba(185, 231, 253, 0.3), inset 0 -3px 8px rgba(185, 231, 253, 0.5);
    background-color: #b9f3fe;
}

.dimmer-off {
    background-color: #283446;
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.2), 0 1px 0 rgba(255, 255, 255, 0.1), 0 0 10px rgba(185, 231, 253, 0), inset 0 0 8px rgba(0, 0, 0, 0.9), inset 0 -2px 5px rgba(0, 0, 0, 0.3), inset 0 -5px 5px rgba(0, 0, 0, 0.5);
}


.error {
    color: red;
}