.switch-container {
    position: relative;
    /* Add this to establish a positioning context */
    border: 2px solid #ececec;
    background-color: #fff;
    padding: 8px;
    /* Adjusted for visual balance */
    box-sizing: border-box;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    height: 100%;
    width: 100%;

}

.switch-name {
    font-size: 20px;
    color: #333;
    padding-top: 4px
}

.loading {
    color: #333;
    margin-top: 20px;
}

.custom-switch {
    cursor: default !important;
}